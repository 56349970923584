import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, {locale})
Vue.config.productionTip = false
import '@/assets/css/style.css'
import '@/assets/css/slicy.css'
import 'wowjs/css/libs/animate.css'
import cookies from 'js-cookie'
import JsonViewer from 'vue-json-viewer'

Vue.prototype.$cookies = cookies

import wow from 'wowjs'

import {axios} from '@/utils/request'

Vue.prototype.axios = axios
Vue.prototype.$wow = wow
Vue.prototype.Domain = 'http://admin.mentostelecom.com/mentos/download?id='
import animate from 'animate.css'
import 'vue-json-viewer/style.css'

Vue.use(JsonViewer)
Vue.use(animate)

const whitelist = ["/", "/product", "/support", "/login", "/createAccount"]
router.beforeEach((to, from, next) => {
    if (whitelist.includes(to.path)) {
        next()
    } else {
        var url = window.location.hash;
        if (url.match(/\?refresh-user=1$/)) {
            store.dispatch('refreshUserInfo')
        }
        if (store.state.userInfo || url.indexOf("dashboard?") != -1) {
            next()
        } else {
            next({path: '/login'})
        }
    }

})
new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        //在页面加载时读取localStorage里的状态信息
        if (localStorage.getItem("store")) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem("store"))))
        }
        window.addEventListener("beforeunload", () => {
            localStorage.setItem("store", JSON.stringify(this.$store.state))
        })
    }
}).$mount('#app')
